.row_between {
  justify-content: space-between;
}
.logo .img-container {
  max-width: 217px;
}
.row__search {
  display: flex;
  justify-content: flex-end;
  -ms-align-items: center;
  align-items: center;
}
.search {
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: url("img/search-desktop.png") no-repeat;
  -webkit-background-size: 100%;
  background-size: 100%;
  cursor: pointer;
}
.nav {
  &bar {
    margin-top: 0.25rem;
    padding-right: 0;
    padding-left: 0;
    border-top: 1px solid #c5c5c5;
    .navbar-nav {
      justify-content: space-between;
      width: 100%;
      .nav-item {
        border-right: 1px solid #959595;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
.navbar-expand-xl .nav-item:first-child .nav-link {
  padding-left: 0;
}
.navbar-expand-xl .nav-item:last-child .nav-link {
  padding-right: 0;
}
.nav-link {
  line-height: initial;
  text-transform: uppercase;
  color: #3f3f3f;
}
.navbar-expand-xl .navbar-nav .nav-link {
  padding: 0 20px;
}
.nav-link:hover,
.nav-link_active,
.nav-link_active .nav-link {
  color: #f68427;
}
.search-mobile {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  flex-direction: column;
  padding-right: 5px;
  padding-left: 10px;
  background: #ed3c2e;
  z-index: 1;
}
.search-mobile__input-container {
  position: relative;
  margin: 0 auto;
  padding-top: 25px;
  width: 1000px;
  max-height: 120px;
}
.search-mobile__input {
  padding-left: 100px;
  border: none;
  border-bottom: 3px solid #fff;
  width: 100%;
  font-size:30px;
  text-transform: uppercase;
  color: #fff;
  background: none;
  outline: none;
}
.search-mobile__input::placeholder {
  padding-left: 10px;
  color: rgba(255,255,255,1);
}
.search-mobile__input:focus::placeholder {
  color: rgba(255,255,255,.5);
}
.search-mobile__btn {
  display: none;
}
.search-mobile__close {
  position: absolute;
  bottom: 10px;
  border: none;
  width: 34px;
  height: 30px;
  background: url("img/arrow-white_big.png");
  -webkit-background-size: 100%;
  background-size: 100%;
}
.search-mobile{
  &__results {
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
    width: 1000px;
    overflow-y: auto;
    &-list {
      list-style: none;
    }
    &-item {
      margin-bottom: 15px;
      color: #fff;
    }
    &-data {
      margin-right: 20px;
      font-size: 16px;
    }
    &-name {
      font-size: 24px;
      line-height: 30px;
      text-decoration: underline;
      color: #fff;
      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
.brand-link {
  display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
  width: 80px;
  height: 45px;
  img {
      max-height: 100%;
  }
}
@media (max-width: 1199px) {
  .main-header {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 1;
  }
  .row__search {
    padding-right: 8px;
  }
  .search__input,
  .search__border,
  .navbar {
    display: none;
  }
  .logo__img {
    min-width: 80px;
  }
  .search_mobile {
    margin-right: 7px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .search::before {
    display: none;
  }
  .search_mobile::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #e6e6e8 url(img/search_white.png) center no-repeat;
    background-size: 50%;
  }
  .search-mobile {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    flex-direction: column;
    padding-right: 5px;
    padding-left: 10px;
    background: #ed3c2e;
  }
  .search-mobile__input-container {
    position: relative;
    margin: 0 auto;
    padding-top: 25px;
    padding-right: 60px;
    width: 95%;
    max-height: 60px;
  }
  .search-mobile__input {
    padding-left: 0;
    padding-right: 30px;
    border: none;
    border-bottom: 2px solid #fff;
    width: 100%;
    font-size:15px;
    text-transform: uppercase;
    color: #fff;
    background: none;
    outline: none;
  }
  .search-mobile__input::placeholder {
    color: #fff;
  }
  .search-mobile__btn {
    position: absolute;
    top: 25px;
    right: 60px;
    display: block;
    border: none;
    width: 32px;
    height: 34px;
    background: url(./img/search_white.png) center no-repeat;
    -webkit-background-size: 50%;
    background-size: 50%;
    outline: none;
  }
  .search-mobile__close {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 25px;
    border: none;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #fff url(./img/search_close.png) center no-repeat;
    -webkit-background-size: 50%;
    background-size: 35%;
    outline: none;
  }
  .search-mobile{
    &__results {
      margin-top: 30px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 10px;
      padding-left: 10px;
      width: 95%;
      overflow-y: auto;
      &-list {
        list-style: none;
      }
      &-item {
        margin-bottom: 15px;
        color: #fff;
      }
      &-data {
        font-size: 10px;
      }
      &-name {
        display: block;
        margin-top: -5px;
        font-size: 12px;
        line-height: 14px;
        text-decoration: underline;
        color: #fff;
        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
  .mobile-nav__btn {
    border: none;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #ed3c2e url(./img/mobile-nav__btn.png) center no-repeat;
    -webkit-background-size: 50%;
    background-size: 50%;
    outline: none;
  }
  .search-mobile__btn:focus,
  .search-mobile__close:focus,
  .mobile-nav__btn:focus{
    outline: none;
  }
  .mobile-nav{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 90px 22px 45px;
    width: 265px;
    height: 100vh;
    background: #fff;
    -webkit-transition: transform .3s ease-out;
    -moz-transition: transform .3s ease-out;
    -ms-transition: transform .3s ease-out;
    -o-transition: transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  .mobile-nav__item {
    border-bottom: 2px solid #e5e5e5;
  }
  .mobile-nav__item:last-child {
    border-bottom: none;
  }
  .mobile-nav__item_dl {
    position: relative;
  }
  .mobile-nav__item_dl:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 8px;
    height: 15px;
    background: url(./img/menu-arrow.png) center no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
  }
  .dl-back {
    position: relative;
  }
  .dl-back::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
    width: 8px;
    height: 15px;
    background: url(./img/menu-arrow.png) center no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
  }
  .mobile-nav__link {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    color: #3f3f3f;
  }
  .mobile-nav__link_back {
    padding-left: 20px;
  }
  .mobile-nav__item_horeca {
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: none;
    font-size: 12px;
    text-transform: uppercase;
    color: #3f3f3f;
  }
  .mobile-nav__item_no-bd {
    border-bottom: none;
  }
  .dl-subviewopen {
    border-bottom: none;
  }
  .dl-subviewopen::after {
    display: none;
  }
  .mobile-nav__btn_close {
    position: absolute;
    top: 23px;
    right: 8px;
    background: #ed3c2e url(img/mobile-menu_close.png) 50% no-repeat;
    -webkit-background-size: 35%;
    background-size: 35%;
  }
  .brand-link {
    display: none;
  }
}

.nav-link_active .nav-{
  &red {
    color:#ee3124;
  }
 &blue {
    color:#01bce4;
  }
} 