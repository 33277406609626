@font-face {
  font-family: 'Circe Extra';
  src: url('fonts/Circe-ExtraLight.eot');
  src: url('fonts/Circe-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('fonts/Circe-ExtraLight.woff') format('woff'),
  url('fonts/Circe-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Circe Extra';
  src: url('fonts/Circe-ExtraBold.eot');
  src: url('fonts/Circe-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Circe-ExtraBold.woff') format('woff'),
  url('fonts/Circe-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('fonts/Circe-Thin.eot');
  src: url('fonts/Circe-Thin.eot?#iefix') format('embedded-opentype'),
  url('fonts/Circe-Thin.woff') format('woff'),
  url('fonts/Circe-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('fonts/Circe-Light.eot');
  src: url('fonts/Circe-Light.eot?#iefix') format('embedded-opentype'),
  url('fonts/Circe-Light.woff') format('woff'),
  url('fonts/Circe-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('fonts/Circe-Bold.eot');
  src: url('fonts/Circe-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Circe-Bold.woff') format('woff'),
  url('fonts/Circe-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('fonts/Circe-Regular.eot');
  src: url('fonts/Circe-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/Circe-Regular.woff') format('woff'),
  url('fonts/Circe-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
}
html,
body {
  min-width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}
body {
  font-family: 'Circe', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #3f3f3f;
}
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.container {
  max-width: 1200px;
}
.main {
  flex: 1 0 auto;
}
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
ul {
  margin: 0;
  padding: 0;
}
.btn.focus, .btn:focus {
  box-shadow: none;
}
.text {
  font-size: 22px;
  margin-bottom: 30px;
}
button {
  cursor: pointer;
}
a {
  color: #fff;
  &:hover {
    color: #fff;
  }
}