.main-footer {
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  background: #c5c5c5;
}
.main-footer__body {
  padding: 55px 0 40px;
}
.main-footer__center {
  text-align: center;
}
.main-footer__right {
  text-align: right;
}
.strauss-btn {
  padding: 10px 40px;
  color: #fff;
  background: #757575;
}