/****************************************
MAIN
****************************************/

.slick-dotted.slick-slider {
  margin-bottom: 0;
}
.slider .slider-item {
  display: flex;
}
.slider-item {
  background: #f68427;
}
.slider-item__left_orange {
  background: url(./img/slider-bg.jpg) center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.slider-item__right_img1 {
  background: url(./img/img1.jpg) center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.slider-item__body {
  padding-top: 140px;
  padding-right: 74px;
  padding-left: 120px;
}
.title_h3 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 51px;
  font-weight: 300;
  line-height: 50px;
  text-transform: uppercase;
  color: #fff;
}
.title_h3_mb-big {
  margin-bottom: 120px;
}
.title_h3_bigger {
  font-size: 56px;
  font-weight: 700;
}
.btn {
  position: relative;
  padding: 0;
  cursor: pointer;
}
.btn_light {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-right: 75px;
  font-size: 24px;
  color: #fff;
}
.btn_light:hover {
  color: #fff;
}
.btn_light::after {
  content: "";
  position: absolute;
  top: -7px;
  right: 0;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: url(./img/arrow-white.png) center center no-repeat;
  transition: transform .5s ease-out;
}
.btn_light:hover::after {
  transform: translateX(10px);
}
.slick-dots {
  top: 0;
  bottom: initial;
  padding-right: 20px;
  max-width: 1200px;
  text-align: right;
}
.slick-dots li button:before {
  display: none;
}
.slick-dots li button {
  border: 2px solid #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}
.slick-dots li.slick-active button {
  background: #fff;
}
.gradient-wrapper {
  padding-top: 1px;
  background: linear-gradient(to bottom, #efefef 0%,#fff 50%,#fff 100%);
}
.site-sections {
  margin-top: 60px;
  margin-bottom: 60px;
}
.section-card__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 29px 32px;
  border-bottom: 10px solid;
  height: 336px;
  background: #fff;
  overflow-x: hidden;
  transition: background 1s ease-out;
}
.section-card__body:hover .img-container_section-card {
  transform: translateX(35%);
}
.section-card__title {
  display: block;
  align-self: flex-start;
  min-height: 60px;
  font-weight: 700;
  color: #3f3f3f;
  text-transform: uppercase;
}
.img-container_section-card {
  height: 140px;
  transition: transform .5s ease-out;
}
.img_section-card {
  max-height: 100%;
}
.section-card__body_red {
  border-color: #ee3124;
}
.section-card__body_red:hover {
  background: #ee3124;
}
.section-card__body_blue {
  border-color: #01bce4;
}
.section-card__body_blue:hover {
  background: #01bce4;
}
.section-card__body_green {
  border-color: #79a230;
}
.section-card__body_green:hover {
  background: #79a230;
}
.section-card__body_brown {
  border-color: #75451e;
}
.section-card__body_brown:hover {
  background: #75451e;
}
.btn_round {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  padding: 5px 34px;
  border: 2px solid #fff;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  opacity: 0;
  transition: opacity .5s .5s ease-out;
}
.section-card__body:hover .btn_round {
  opacity: 1;
}
.section-card__body:hover .section-card__title {
  color: #fff;
}
.title-container {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
.title_h2 {
  display: inline-block;
  padding-right: 12px;
  padding-left: 12px;
  border-bottom: 5px solid #ee3124;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #000;
}
.news {
  margin-top: 30px;
  margin-bottom: 30px;
}
.news__last {
  padding:1px 44px;
  height: 100%;
  transition: transform 1s ease-out, opacity .5s ease-out;
}
.news__last_bg-green {
  background: #79a230;
}
.title_h4_huge {
  margin-top: 40px;
  margin-bottom: 48px;
  font-size: 51px;
  font-weight: 300;
  line-height: 50px;
  color: #fff;
}
.news__last-img {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.img-container_news-last:first-child{
  padding-bottom: 15px;
}
.img-container_news-last:last-child{
  padding-top: 15px;
}
.img-container_news {
  margin-bottom: 30px;
  border-bottom: 7px solid #80def2;
  max-height: 300px;
}
.title_h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #000;
}
.news-item__date {
  font-size: 16px;
  line-height: 32px;
  color: #babfc6;
}
.btn_red {
  padding-right: 33px;
  font-size: 20px;
  line-height: 32px;
  color: #333;
}
.btn_red::after {
  content: "";
  position: absolute;
  top: 7px;
  right: 0;
  width: 17px;
  height: 16px;
  background: url(./img/arrow-red.png);
  transition: transform .5s ease-out;
}
.btn_red:hover::after {
  transform: translateX(10px);
}
.news-buttons {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}
.btn_news-add {
  display: block;
  margin: 0 auto 20px;
  border: 2px solid #bec4c8;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  font-size: 25px;
  color: #bec4c8;
  text-align: center;
  background: none;
}
.btn_news-all {
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #bdc3c7;
  background: none;
}



/****************************************
PAGES
****************************************/

  /***** ABOUT ****/

.active_orange,
.active_orange:hover {
  font-weight: normal;
  color: #f68427;
}
.internal-nav {
  max-width: 1170px;
  margin: 0 auto;
}
.internal-nav_orange {
  background: #f68427;
}
.internal-nav__list {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding-top: 11px;
}
.internal-nav__item_active {
  border-bottom: 5px solid #fff;
}
.internal-nav__item:hover {
  border-bottom: 5px solid #fff;
}
.internal-nav__link {
  display: block;
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
}
.internal-nav__link:hover {
  text-decoration: none;
  color: #fff;
}
.section-page {
  padding: 1px 0;
  min-height: 777px;
}
.section-page_about {
  background: #d7d7d7 url(./img/about-bg.jpg) center no-repeat;
  background-size: cover;
}
.content-area {
  margin-top: 67px;
  margin-bottom: 67px;
  min-height: 540px;
  color: #fff;
  background: rgba(0,0,0,.7);
}
.content-area__body {
  position: relative;
  top: 0;
  left: 0;
  display: none;
  padding: 55px 64px 55px 73px;
  height: 100%;
}
.content-area__body_active {
  display: flex;
}
.content-area__body_scroll {
  padding-right: 30px;
}
.titles-container,
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.text-container {
  display: none;
  max-height: 410px;
}
.title_h2-content {
  margin-bottom: 40px;
  font-size: 50px;
  line-height: 50px;
}
.title_h2-about_big {
  font-size: 70px;
  font-weight: 700;
}
.title_h2-about_upper {
  text-transform: uppercase;
}
.title_h2-about_mb0 {
  margin-bottom: 0;
}
.title_h2-about_mb-small {
  margin-bottom: 20px;
}
.title_h2-about_sub {
  display: block;
  font-size: 20px;
  line-height: 30px;
}
.text-container {
  padding-right: 30px;
  padding-left: 40px;
}
.next-page {
  position: absolute;
  bottom: 20px;
  right: 64px;
  display: inline-block;
  padding-right: 29px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}
.next-page:hover {
  color: #fff;
}
.next-page::after {
  content: "";
  position: absolute;
  top: 7px;
  right: 0;
  width: 16px;
  height: 14px;
  background: url(./img/arrow-white.png);
}
.titles-container_top,
.text-container_top {
  justify-content: flex-start;
  align-items: flex-start;
}
.text-btn {
  padding: 0;
  border: none;
  text-align: left;
  text-decoration: underline;
  color: #fff;
  background: none;
}
.text-btn:focus {
  outline: none;
}
.text-btn_active,
.text-btn:hover {
  text-decoration: none;
  color: #f68427;
}
.simplebar-track {
  width: 12px;
}
.simplebar-scrollbar,
.simplebar-track .simplebar-scrollbar.visible {
  opacity: 1;
}
.text-container_scroll {
  padding-right: 0;
}
.simplebar-content {
  padding-right: 55px;
  padding-bottom: 50px !important; 
}
.simplebar-track.vertical {
  right: 4px;
  width: 2px;
  height: 100%;
  text-align: center;
  background: #fff;
}
//.vertical-line {
//  display: inline-block;
//  width: 2px;
//  height: 95%;
//  background: #fff;
//}
.simplebar-scrollbar {
  right: -4px;
  margin-top: -2px;
  border-radius: 0;
  width: 10px;
  background: #f68427;
}
.text_title-upper {
  margin-bottom: 0;
  text-transform: uppercase;
}

  /***** PRODUCTION ****/

.section-page_production {
  background: #d7d7d7 url(./img/production-bg.jpg) center no-repeat;
  background-size: cover;
}
.content-area__left_no-pr {
  padding-right: 0;
}
.internal-nav__list_start {
  justify-content: flex-start;
}
.internal-nav__item_m {
  margin-right: 30px;
  margin-left: 30px;
}
.img-container_self-center {
  align-self: center;
}
.img-container_pr40 {
  padding-right: 40px;
}
.img-container_mb20,
.title_h2-mb20 {
  margin-bottom: 20px;
}
.text_title {
  font-size: 35px;
  font-weight: 700;
}
.text_title_small {
  margin-bottom: 0;
  font-weight: 700;
}
.simplebar-scroll-content {
  padding-right: 18px !important;
}
.ordered-list {
  padding-left: 0;
  list-style-position: inside;
}
.ordered-list__item {
  margin-bottom: 20px;
}
.unordered-list_style-none {
  list-style: none;
}
.content-area__body .text-container:first-child {
  display: flex;
}

  /***** CAREER ****/

.main_bg-grey {
  background: linear-gradient(180deg,#efefef 0,#fff 50%,#fff);
}
.news__left {
  display: flex;
  overflow: hidden;
}
.news__last {
  min-width: 100%;
}
.internal-nav_career {
  margin-top: 30px;
  margin-bottom: 30px;
}
.internal-nav__list_career {
  justify-content: space-between;
  flex-wrap: wrap;
}
.internal-nav__item_career:hover {
  border-bottom: none;
}
.internal-nav__link_career {
  font-size: 22px;
  text-decoration: underline;
  color: rgba(63,63,63,.3);
}
.internal-nav__link_career_active,
.internal-nav__link_career:hover {
  text-decoration: none;
  color: rgba(63,63,63,1);
}
.vacancies-container {
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 840px;
}
.resume {
  flex: 0 0 27%;
  padding-right: 0;
  max-width: 27%;
}
.resume_mobile {
  display: none;
}
.resume__body {
  padding: 30px 25px;
  background: #fff;
  -webkit-box-shadow: 0 0 13px 2px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 13px 2px rgba(0,0,0,0.1);
  box-shadow: 0 0 13px 2px rgba(0,0,0,0.1);
}
.resume__title {
  position: relative;
  padding-left: 67px;
  font-size: 22px;
  font-weight: 300;
  line-height: 26px;
  text-transform: uppercase;
}
.resume__title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 57px;
  height: 74px;
  background: url(./img/resume.svg);
}
.resume__email {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
  color: #0a90ca;
}
.text_resume {
  line-height: 1.15;
}
.vacancies {
  padding-left: 35px;
  flex: 0 0 73%;
  max-width: 73%;
}
.vacancy {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #9d9d9d;
}
.vacancy:last-child {
  border-bottom: none;
}
.vacancy__title {
  display: flex;
  justify-content: space-between;
}
.vacancy__date {
  padding-right: 15px;
}
.title_vacancy {
  margin-top: 0;
  font-size: 30px;
  font-weight: 700;
  color: #3f3f3f;
}
.title_vacancy_upper {
  font-weight: 700;
  text-transform: uppercase;
}
.text_vacancy {
  line-height: 24px;
}
.btn_vacancy {
  padding: 13px 50px 9px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  background: #79a230;
}
.btn_vacancy:hover {
  color: #79a230;
  background: rgba(63,63,63,.1);
}
.text_ul {
  margin-bottom: 0;
  font-weight: 700;
}
.unordered-list_vacancy {
  list-style: none;
  margin-bottom: 30px;
}
.list-item_vacancy {
  position: relative;
  padding-left: 20px;
  line-height: 24px;
}
.list-item_vacancy::before {
  content: '•';
  position: absolute;
  top: 0;
  left: 0;
}
.btn_vacancy-open {
  font-size: 24px;
  color: #79a230;
}
.btn_vacancy-open:hover {
  color: #79a230;
}
.btn_vacancy-open::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  margin-bottom: 5px;
  border: 2px solid #79a230;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: url(./img/arrow-green.png) center no-repeat;
}

  /***** CONTACTS *****/

.contacts {
  margin-top: 30px;
  margin-bottom: 60px;
}
.contacts__left {
  display: flex;
  flex-grow: 1;
}
#map {
  width: 100%;
}
.contacts__item {
  padding: 25px;
  transition: background .5s ease-out, color .5s ease-out;
  cursor: pointer;
}
.contacts__item_orange {
  margin-bottom: 30px;
  color: #c5c5c5;
  background: #f5f5f5;
  &:hover {
    color: #fff;
    background: #f38423;
    .title_contacts {
      color: #fff;
    }
  }
}
.contacts__item_orange.selected {
  color: #fff;
  background: #f38423;
  cursor: default;
  .title_contacts {
    color: #fff;
  }
}
.contacts__item_grey {
  color: #c5c5c5;
  background: #f5f5f5;
  &:hover {
    color: #fff;
    background: #01bce4;
    .title_contacts {
      color: #fff;
    }
  }
}
.contacts__item_grey.selected {
  color: #fff;
  background: #01bce4;
  cursor: default;
  .title_contacts {
    color: #fff;
  }
}
.contacts__item_orange .title_contacts,
.contacts__item_grey .title_contacts {
  color: #c5c5c5;
}
.title_contacts {
  text-transform: uppercase;

}
.text_no_m {
  margin-bottom: 0;
}
.contacts-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}
.contacts__type,
.contacts__text {
  display: block;
}
.contacts__type {
  line-height: 24px;
  color: #c5c5c5;
}
.contacts__text {
  font-size:24;
  text-transform: uppercase;
}
a.contacts__text {
  color: #3f3f3f;
  &:hover {
    color: #3f3f3f;
  }
}

  /***** DRINKS *****/

.active_red,
.active_red:hover {
  font-weight: normal;
  color: #ee3124;
}
.internal-nav_red {
  background: #ee3124;
}
.active_blue,
.active_blue:hover {
  font-weight: normal;
  color: #01bce4;
}
.internal-nav_blue {
  background: #01bce4;
}
.section-page_black-card {
  background: #d7d7d7 url(./img/black-card-bg.jpg) center bottom no-repeat;
  background-size: cover;
}
.section-page_drinks {
  background: #d7d7d7 url(./img/drinks-bg.jpg) center bottom no-repeat;
  background-size: cover;
}
.section-page_le-cafe {
  background: #d7d7d7 url(./img/le-cafe-bg.jpg) center bottom no-repeat;
  background-size: cover;
}
.active_red {
  color: #ee3124;
}
.active_blue {
  color: #01bce4;
}
.content-area_drinks {
  position: relative;
  min-height: 930px;
}
.content-area__left_drinks {
  padding-right: 0;
}
.content-area__right_drinks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  padding-left: 30px;
}
.content-area__right_drinks .text-container_select:first-child {
  display: flex;
}
.content-area__right_drinks .text-container_select {
  display: none;
}
.text-container_drinks {
  max-height: none;
}
.drinks {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  width: 100%;
}
.drinks__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  padding: 0 15px;
  flex-basis: 25%;
  max-width: 25%;
  height: 300px;
  font-size: 15px;
  line-height: 16px;
  text-transform: uppercase;
}
.img-container_drinks {
  align-items: flex-end;
  margin-bottom: 20px;
  max-height: 100%;
  height: 75%;
  img {
    max-height: 100%;
  }
}
.drinks__name {
  padding: 0 8px;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  min-height: 32px;
  color: #c5c5c5;
}
.drinks__name:hover {
  border-right: 4px solid #fff;
  border-left: 4px solid #fff;
  text-decoration: none;
  color: #fff;
}
.content-area .content-area__body {
  display: none;
}
.content-area .content-area__body:first-child {
  display: flex;
}
.text_slogan {
  margin-top: 60px;
  font-size: 20px;
  letter-spacing: -0.02em;
  font-weight: 700;
  text-transform: uppercase;
}

.text-btn_red:hover,
.text-btn_active_red {
  color: #ee3124;
}
.drink {
  position: relative;
  padding-right: 350px;
    .drink__text {
        padding-right: 0;
    }
}
.text-btn_return {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  padding-left: 29px;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
}
.text-btn_return::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 16px;
  height: 14px;
  background: url(img/arrow-white.png);
  transform: rotate(180deg);
}
.text-btn_return:hover {
  color: #fff;
  text-decoration: underline;
}
.drink__title {
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
}
.drink__img {
  position: absolute;
  top: 50%;
  right: -35px;
  max-height: 100%;
  transform: translateY(-50%);
}
.drink__text {
  margin-bottom: 90px;
  padding-right: 350px;
  .text_p-drink {
    padding-right: 0;
  }
}
.drink__options {
  margin: 60px 0;
  line-height: 28px;
}
.drink__option {
  margin-bottom: 20px;
}
.drink__buttons {
  position: relative;
  text-align: center;
  z-index: 2;
}
.drink__prev,
.drink__next {
  display: inline-block;
  margin: 0 20px;
  border: 2px solid #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: url(img/arrow-white.png) center no-repeat;
}
.drink__prev {
  transform: rotate(180deg);
}
.text_big {
  font-size: 25px;
}
.drink__img-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 670px;
  max-width: 380px;
}

  /***** HORECA *****/

.section-page_horeca {
  background: #eaeaea url(./img/horeca-bg.jpg) center no-repeat;
  background-size: cover;
}
.content-area_horeca {
  min-height: 1160px;
}
.internal-nav__item_horeca {
  font-size: 15px;
  text-transform: uppercase;
  cursor: default;
}
.internal-nav__item_horeca:hover {
  border-bottom: none;
}
.drinks_horeca {
  margin-top: 40px;
  padding-left: 0;
}
.next-page_horeca {
  position: relative;
  bottom: initial;
  right: initial;
  margin-left: auto;
  padding-right: 29px;
}
.row__category,
.mobile-nav__btn,
.search-mobile,
.mobile-nav,
.page-btn,
.main-footer__content-mobile,
.title_h3-mobile,
.btn_drink,
.btn_horeca {
  display: none;
}
.horeca-item {
  position: relative;
}
.horeca-item__description {
  margin-bottom: 40px;
  padding-right: 43%;
}
.horeca-item__img-container {
  position: absolute;
  top: 0;
  right: -35px;
  width: 350px;
  max-width: 350px;
  height: 400px;
    img {
      margin: 0 auto;
      max-height: 100%;
    }
}
.horeca-item__features,
.horeca-item__chars{
  margin-bottom: 40px;
  font-weight: 300;
}
.horeca-item__chars-list {
  list-style: none;
}
.horeca-item__chars-item::after {
  content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ';
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.horeca-item__chars-item span:first-child {
  float: left;
}
.horeca-item__chars-item span:last-child {
  float: right;
}
.text_p-drink {
  padding-right: 43%;
}




  /***** NEWS OPEN *****/

.news-head__content {
  position: relative;
  text-align: center;
}
.text-btn_return_news {
  position: absolute;
  top: 25px;
  left: 34px;
  color: #333;
}
.text-btn_return_news:hover,
.text-btn_return_news:active {
  color: #333;
}
.text-btn_return_news::before {
  width: 17px;
  height: 16px;
  background: url(./img/arrow-red.png);
}

.img-container_news_open {
  display: inline-block;
}
.news-item__body_open {
  margin: 0 auto;
  max-width: 600px;
}
.news-item__head {
  margin-bottom: 50px;
}
.title_h2_open {
  padding: 0;
  border-bottom: none;
  font-size: 50px;
  line-height: 50px;
  font-weight: 300;
  text-align: left;
  text-transform: none;
}
.news-media {
  margin-top: 80px;
  margin-bottom: 80px;
}
.news-media__body {
  margin: 0 auto;
  max-width: 1028px;
}
.news-media__content {
  display: flex;
}
.img-container_news-media {
  width: 50%;
}
.news-media__note {
  padding: 95px 85px 50px 100px;
  width: 50%;
}
.text_note {
  position: relative;
  font-size: 30px;
  line-height: 40px;
  color: #000;
}
.text_note::before {
  position: absolute;
  top: -57px;
  left: 0;
  content: "";
  width: 200px;
  height: 7px;
  background: #000;
}
.title-container_news-open {
  margin-top: 90px;
  margin-bottom: 90px;
}
.news-item__date {
  display: block;
  margin-bottom: 5px;
}
.img-container_news-media:not(:first-child) {
  display: none;
}



/****************************************
MEDIA
****************************************/

@media (max-width: 1199px) {
  /***** General *****/

  .row__category,
  .mobile-nav,
  .page-btn {
    display: flex;
  }
  .mobile-nav__btn,
  .btn_drink,
  .btn_horeca {
    display: inline-block;
  }
  .main-footer__content-mobile,
  .title_h3-mobile {
    display: block;
  }
  .next-page_mobile::after,
  .prev-page_mobile::after {
    top: 0;
  }
  p {
    font-size: 12px;
    line-height: 15px;
  }

  /***** Index page *****/

  .main {
    padding: 0;
  }
  .container_no-p {
    padding: 0;
  }
  .slider-item__right {
    display: none;
  }
  .slick-dots {
    padding-top: 5px;
    text-align: left;
  }
  .slick-dots li {
    margin: 0 3px;
    width: 8px;
    height: 8px;
  }
  .slick-dots li button {
    width: 8px;
    height: 8px;
    padding: 2px;
  }
  .slider-item__body {
    padding-top: 5px;
    padding-right: 41px;
    padding-left: 41px;
  }
  .title_h3 {
    font-size: 16px;
    line-height: 20px;
  }
  .title_h3_bigger {
    font-size: 20px;
  }
  .title_h3_mb-big {
    margin-bottom: 20px;
  }
  .btn_light {
    margin-top: 0;
    margin-bottom: 20px;
    padding-right: 42px;
    font-size: 11px;
  }
  .btn_light:after {
    top: -5px;
    border-width: 1px;
    width: 25px;
    height: 25px;
    -webkit-background-size: 50%;
    background-size: 50%;
  }
  .site-sections {
    margin: 0;
    padding-top: 15px;
  }
  .section-card {
    margin-bottom: 15px;
    padding-right: 6px;
    padding-left: 6px;
  }
  .section-card__body {
    padding: 13px 16px 5px;
    border-width: 5px;
    height: 170px;
  }
  .section-card__title {
    margin-bottom: 10px;
    min-height: 24px;
    font-size: 12px;
    line-height: 12px;
  }
  .btn_round {
    padding: 4px 20px;
    font-size: 11px;
    line-height: 16px;
  }
  .title_h2 {
    font-size: 20px;
  }
  .title-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .news {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .news_p {
    padding-right: 15px;
    padding-left: 15px;
  }
  .news__right {
    display: none;
  }
  .title_h4_huge {
    margin: 25px 0;
    font-size: 20px;
    line-height: 20px;
  }
  .news__last {
    padding: 1px 30px;
  }
  .news-item {
    margin-bottom: 30px;
  }
  .news-item:last-child {
    margin-bottom: 0;
  }
  .img-container_news {
    margin-bottom: 10px;
    border-width: 5px;
  }
  .title_h4 {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 15px;
  }
  .news-item__date {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 15px;
  }
  .text {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 15px;
  }
  .btn_red {
    font-size: 11px;
    line-height: 16px;
  }
  .btn_red:after {
    top: 4px;
    width: 19px;
    background-repeat: no-repeat;
    -webkit-background-size: 50%;
    background-size: 50%;
  }
  .main-footer__body {
    padding-top: 5px;
    padding-bottom: 18px;
  }
  .main-footer__content {
    display: none;
  }
  .main-footer__content-mobile {
    margin: 0 auto;
    padding-right: 25px;
    padding-left: 25px;
    max-width: 350px;

  }
  .main-footer__text-mobile {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
  }
  .main-footer__text-mobile:nth-last-child(2) {
    border-bottom: none;
  }
  .text_footer-mobile {
    margin-bottom: 0;
    font-size: 15px;
  }
  .text_flex {
    display: flex;
    justify-content: space-between;
  }
  .text_upper {
    text-transform: uppercase;
  }
  .text_email {
    position: relative;
    padding-left: 40px;
    padding-top: 4px;
  }
  .text_email::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 18px;
    height: 13px;
    background: url(./img/footer-mail.png);
    -webkit-background-size: cover;
    background-size: cover;
  }
  .text_small {
    font-size: 11px;
    line-height: 12px;
  }
  .strauss-btn {
    display: block;
    padding-top: 6px;
    padding-bottom: 4px;
    width: 100%;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
  }

  /***** About page *****/

  .main {
    background: #000;
  }
  .main_no-bg {
    background: none;
  }
  .internal-nav {
    display: none;
  }
  .row__category {
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
  }
  .section-page {
    padding: 0;
    min-height: auto;
  }
  .section-page_about {
    position: relative;
    background: none;
    min-height: 750px;
  }
  .section-page_about::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    width: 920px;
    height: 620px;
    background: linear-gradient(180deg,rgba(0,0,0,.5) 0,rgba(0,0,0,.5) 75%,#000), url(./img/about-bg.jpg) no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-position: 0 0;
  }
  .text_category {
    margin-bottom: 0;
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
  }
  .content-area {
    margin: 0;
    height: 100%;
    background: none;
  }
  .content-area__body {
    padding: 20px 33px;
  }
  .title_h3-mobile {
    margin-top: 0;
    width: 100%;
    font-size: 25px;
    line-height: 25px;
    text-transform: none;
  }
  .titles-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .title_h2-about_big {
    font-size: 35px;
    line-height: 25px;
  }
  .title_h2-about_sub {
    font-size: 12px;
    line-height: 15px;
  }
  .next-page {
    display: none;
  }
  .page-btn {
    display: table;
    margin-bottom: 40px;
    width: 100%;
  }
  /*.page-btn_end {
    justify-content: flex-end;
  }*/
  .page-btn_between {
    justify-content: space-between;
  }
  .next-page_mobile,
  .prev-page_mobile {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
    width: 50%;
    min-height: 25px;
    font-size: 11px;
    line-height: 11px;
    color: #fff;
  }
  .next-page_mobile {
    float: right;
    justify-content: flex-end;
    padding-right: 35px;
    text-align: right;
  }
  .prev-page_mobile{
    float: left;
    padding-left: 35px;
  }
  .next-page_mobile::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: url(./img/arrow-white.png) center no-repeat;
    -webkit-background-size: 35%;
    background-size: 35%;
  }
  .prev-page_mobile::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: url(./img/arrow-white.png) center no-repeat;
    -webkit-background-size: 35%;
    background-size: 35%;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .content-area__left,
  .content-area__right{
    padding-right: 0;
    padding-left: 0;
  }
  .content-area__left {
    margin-bottom: 40px;
  }
  .text-container {
    padding: 0;
    max-height: initial;
  }
  .title_h2-content {
    margin-bottom: 20px;
    width: 100%;
    font-size: 25px;
    line-height: 25px;
  }
  .text-btn {
    margin-right: 28px;
    font-size: 15px;
    line-height: 25px;
  }
  .next-page_mobile_long {
    margin-left: 25px;
    display: inline-block;
  }
  .main_bg-grey {
    background: linear-gradient(180deg,#efefef,#fff 50%,#fff);
  }
  .news {
    margin-top: 0;
  }
  .news__left {
    padding-right: 0;
    padding-left: 0;
  }
  .internal-nav_career {
    display: block;
    margin: 25px -9px;
  }
  .resume {
    display: none;
  }
  .resume_mobile {
    display: block;
    flex-basis: 100%;
    max-width: 100%;
  }
  .resume__body {
    padding: 13px 16px;
    padding-left: 87px;
    min-height: 119px;
  }
  .resume__title {
    margin-bottom: 0;
    padding-left: 0;
    font-size: 11px;
    line-height: initial;
  }
  .resume__title:before {
    left: -77px;
    width: 70px;
    height: 90px;
    background: url(img/resume.svg) top/100% no-repeat;
  }
  .resume__email {
    margin-bottom: 0;
    font-size: 14px;
  }
  .text_resume {
    margin-bottom: 0;
  }
  .internal-nav__list_career {
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 0;
  }
  .internal-nav__item_career {
    margin-right: 6px;
  }
  .internal-nav__link_career {
    display: inline;
    border: 1px solid #e5edd7;
    border-radius: 20px;
    padding: 4px 9px;
    font-size: 12px;
    line-height: 30px;
    text-decoration: none;
  }
  .internal-nav__link_career_active,
  .internal-nav__link_career:hover {
    background: #bbd674;
  }
  .vacancies {
    padding-left: 15px;
    flex-basis: 100%;
    max-width: 100%;
  }
  .vacancy:last-child {
    margin-bottom: 0;
  }
  .vacancy__title {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  .title_vacancy {
    width: 100%;
    font-size: 15px;
    line-height: 15px;
  }
  .vacancy__date {
    font-size: 12px;
    line-height: 15px;
  }
  .btn_vacancy {
    padding: 6px 25px 5px;
    font-size: 10px;
    line-height: 9px;
  }
  .list-item_vacancy {
    font-size: 12px;
    line-height: 15px;
  }
  .btn_vacancy-open {
    font-size: 12px;
  }
  .btn_vacancy-open:before {
    margin-right: 13px;
    margin-bottom: 4px;
    border-width: 1px;
    width: 25px;
    height: 25px;
    -webkit-background-size: 35%;
    background-size: 34%;
  }
  .section-page_black-card {
    position: relative;
    min-height: 750px;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: left;
  }
  .section-page_black-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,rgba(0,0,0,.5),rgba(0,0,0,.5) 75%,#000);
  }
  .content-area__body_drinks {
    padding-right: 15px;
    padding-left: 17px;
  }
  .img-container_drinks_mobile {
    justify-content: flex-start;
    max-width: 100%;
  }
  .img-container_drinks_mobile .img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
    width: 21%;
  }
  .text_slogan {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
    max-width: 75%;
    font-weight: 400;
  }
  .text-btn {
    width: 100%;
  }
  .drinks {
    padding-left: 0;
  }
  .drinks__item {
    flex-basis: 50%;
    margin-bottom: 40px;
    max-width: 50%;
  }
  .drinks__name {
    margin-bottom: 10px;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
  }
  .drinks__name:hover {
    border: none;
  }
  .btn_drink {
    padding: 9px 25px 8px;
    border-radius: 0;
    font-size: 10px;
    line-height: 9px;
    color: #fff;
    background: #ed3c2e;
  }
  .section-page_drinks,
  .section-page_le-cafe,
  .section-page_production,
  .section-page_horeca {
    position: relative;
    min-height: 750px;
    background: none;
  }
  .section-page_drinks::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    width: 920px;
    height: 620px;
    background: linear-gradient(180deg,rgba(0,0,0,.5),rgba(0,0,0,.5) 75%,#000),url(img/drinks-bg.jpg) no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .img-container_drinks_mobile .img_long {
    width: 25%;
  }
  .img-container_drinks_mobile .img_ambassador {
    width: 36%;
  }
  .img-container_drinks_mobile .img_elite {
    width: 33%;
  }
  .text_slogan_long {
    max-width: 71%;
    font-size: 10px;
  }
  .text_slogan_ambassador {
    max-width: 55%;
    font-size: 10px;
  }
  .drinks__name {
    color: #fff;
  }
  .section-page_le-cafe::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    width: 920px;
    height: 620px;
    background: linear-gradient(180deg,rgba(0,0,0,.5),rgba(0,0,0,.5) 75%,#000),url(img/le-cafe-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
  }
  .content-area_drinks {
    min-height: initial;
  }
  .content-area__left_drinks_open,
  .text-btn_return:before{
    display: none;
  }
  .drink {
    padding-right: 0;
  }
  .text-btn_return {
    margin-right: 0;
    margin-bottom: 25px;
    padding-left: 0;
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: underline;
  }
  .drink__title {
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 400;
    line-height: 25px;
  }
  .drink .prev-page_mobile:after,
  .drink .next-page_mobile:after {
    top: 0;
  }
  .drink__img-container {
    position: static;
    margin-bottom: 20px;
    height: auto;
    min-width: initial;
  }
  .drink__img {
    position: static;
    transform: translateY(0);
  }
  .drink__text {
    margin-bottom: 30px;
  }
  .drink__options {
    margin: 30px 0;
  }
  .text_no_m {
    margin-bottom: 0;
  }
  .drink__buttons {
    display: none;
  }

    /***** CONTACTS *****/

  .contacts {
    margin-bottom: 15px;
  }
  .contacts__left {
    min-height: 150px;
  }
  .contacts__body {
    display: flex;
    margin-bottom: 15px;
  }
  .contacts__item {
    padding: 13px;
    padding-right: 15px;
    width: 50%;
  }
  .contacts__item_orange {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .title_contacts {
    font-size: 12px;
  }
  .contacts__item_grey {
    background: #fff;
  }
  .contacts-list {
    flex-wrap: wrap;
  }
  .contacts__type {
    font-size: 12px;
  }
  .contacts__text {
    font-size: 15px;
  }

  /***** PRODUCTION *****/

  .section-page_production::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    width: 920px;
    height: 620px;
    background: linear-gradient(180deg,rgba(0,0,0,.5),rgba(0,0,0,.5) 75%,#000),url(img/production-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
  }
  .text_title {
    font-size: 18px;
    line-height: 25px;
  }
  .list-item,
  .ordered-list__item,
  .unordered-list__item {
    font-size: 12px;
    line-height: 15px;
  }

  /***** HORECA *****/

  .section-page_horeca::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    width: 920px;
    height: 620px;
    background: linear-gradient(180deg,rgba(0,0,0,.5),rgba(0,0,0,.5) 75%,#000),url(img/horeca-mobile-bg.jpg) no-repeat;
    background-size: cover;
    background-position: left;
  }
  .content-area_horeca {
    min-height: auto;
  }
  .unordered-list {
    list-style: none;
  }
  .content-area__left_no-m {
    margin-bottom: 0;
  }
  .btn_horeca {
    padding: 9px 25px 8px;
    border-radius: 0;
    font-size: 10px;
    line-height: 9px;
    color: #fff;
    background: #08c2e7;
  }
  .horeca-item__description {
    padding-right: 0;
  }
  .horeca-item__img-container {
    position: relative;
    margin-bottom: 40px;
    height: auto;
    width: auto;
    max-width: initial;
  }
  .horeca-item__img {
    position: static;
  }
  .text_title_small {
    margin-bottom: 0;
  }
  .horeca-item__chars-list {
    max-width: 450px;
    font-size: 12px;
    line-height: 15px;
  }
  .horeca-item__chars-item span:last-child {
    width: auto;
  }

  /***** NEWS OPEN *****/

  .news-head__body {
    padding: 0;
  }
  .text-btn_return_news {
    top: 23px;
    left: 18px;
  }
  .img-container_news_open {
    border-bottom: none;
    border-top: 7px solid #80def2;;
  }
  .news-item__head {
    margin-bottom: 15px;
  }
  .title_h2_open {
    font-size: 15px;
    line-height: 15px;
    font-weight: 700;
  }
  .news-media {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .news-media__body {
    padding: 0;
  }
  .news-media__note {
    display: none;
  }
  .title-container_news-open {
    display: none;
  }
  .news-item_teaser:last-child {
    display: none;
  }
  .news-item_teaser .news-item__date,
  .news-item_teaser .btn_red {
    display: none;
  }
  .news-item_teaser {
    padding-right: 8px;
    padding-left: 8px;
    width: 50%;
  }
  .title_h4_teaser {
    font-size: 11px;
    line-height: 11px;
  }
  .news-item_teaser:nth-of-type(1) .title_h4 {
    padding-left: 58px;
  }
  .news-item_teaser:nth-of-type(2) .title_h4{
    padding-right: 58px;
  }
  .news-item_teaser:nth-of-type(1) .title_h4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 30px;
    border: 1px solid #ee3124;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: url(img/arrow-red.png) 50% no-repeat;
    background-size: 35%;
    transform: rotate(180deg);
  }
  .news-item_teaser:nth-of-type(2) .title_h4::after {
    content: "";
    position: absolute;
    top: 0;
    right: 30px;
    border: 1px solid #ee3124;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: url(img/arrow-red.png) 50% no-repeat;
    background-size: 35%;
  }
}

























